@import '../../../../aveda_base/scss/_theme-bootstrap';

#legal-popup-coresites {
  display: none;
  height: 155px;
  background-color: $black;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1000;
  width: 300px;
  padding: 10px;
  border: 1px solid $white;
  p {
    color: $white;
    text-align: left;
    line-height: 18px;
    font-size: 14px;
    font-weight: 300;
  }
  a {
    text-decoration: underline;
    color: $white;
  }
  .legal-popup-coresites-close {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 19px;
    font-weight: 300;
    height: 30px;
    cursor: pointer;
    z-index: 103;
    color: $white;
  }
  .legal-popup-coresites-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .legal-popup-coresites-content-inner {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
  }
}
